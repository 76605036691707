<template>
	<div>
		<TableList :form_data_seo.sync="form_data_seo" :columns="columns" :get_table_list="this.get_table_list"
		 :slot_table_list="['name','num','operation']" :is_page="false" ref="list" rowKey="id" :submit_preprocessing="submit_preprocessing"
		 @expandedRowsChange="handleExpandedRowsChange($event)" @list_after="list_after">
			<!--  <template slot="right_btn">
                <export-table
                    v-if="this.$store.state.page_auth.export"
                    url="/manage/crm.export/freezerCensus"
                >导出</export-table>
            </template> -->

					<template slot="form_after">
				<div class="page_content">
					<a-row type="flex">
						<a-col style="flex:1" v-for="item in info" :key="item.name" >
							<a-statistic :title="item.name" :value="item.value"></a-statistic>
						</a-col>
					</a-row>
				</div>
			</template>
			<template slot="num" slot-scope="data">
				<a @click="getCostInfo(data.record)">{{data.text}}</a>
			</template>

			<template slot="operation" slot-scope="data">
				<a @click="see(data.record)">查看</a>
			</template>
		</TableList>

		<PopTableList rowKey="id" ref="pop_table_list" :is_created_get_list="false" :get_table_list="get_table_list_pop"
		 :columns="columns_details" :slot_table_list="['num']" :is_page="true" :visible.sync="visible">
			<template slot="num" slot-scope="data">
				<a @click="getCostInfo(data.record)">{{data.text}}</a>
			</template>
		</PopTableList>
	</div>
</template>

<script>
	import TableList from "@/components/TableList";
	import {
		costTopReport,
		costReport,
		getCostSub,
		getCostInfo
	} from "@/api/goods";
	import PopTableList from "@/components/PopTableList";


	const columns = [{
			title: "部门",
			dataIndex: "name",
		},

		{
			title: '物料数量',
			dataIndex: 'num',
			scopedSlots: {
				customRender: "num"
			}
		},
		{
			title: '操作',
			dataIndex: 'operation',
			scopedSlots: {
				customRender: "operation"
			}
		}
	];

	const columns_details = [
		// department
		{
			title: "部门",
			dataIndex: "department",
		},
		{
			title: '物料名称',
			dataIndex: 'name',
			scopedSlots: {
				customRender: "name"
			}
		},
		{
			title: '物料数量',
			dataIndex: 'num',
			scopedSlots: {
				customRender: "num"
			}
		},

	]



	export default {
		name: "Index",
		components: {
			TableList,
			PopTableList
		},
		data() {
			return {
				get_table_list: costReport,
				get_table_list_pop: getCostInfo,
				submit_preprocessing: {
					array_to_string: ['name']
				},
				columns,
				columns_details,
				visible: false,
				info: {},
				form_data_seo: {
					...this.$config.form_data_seo,
					list: [{
						type: "tree-select",
						name: "name",
						title: "部门",
						options: {},
						treeData: [],
						multiple: true
					}],

				},
				form_data: {}
			};
		},
		async created() {
			this.$method.get_department().then(res => {
				this.form_data_seo.list = this.$method.set_form_list(this.form_data_seo.list, 'name', 'treeData', res)
			});
			this.$nextTick(() => {
				this.get_info();
			})

		},
		methods: {
			get_list_key(list, key, index_arr = []) {
				for (let i = 0; i < list.length; i++) {
					if (list[i].id == key) {
						return list[i]
					}

					if (Array.isArray(list[i].children) && list[i].children.length) {
						let value = this.get_list_key(list[i].children, key);
						if (value !== false) {
							return value;
						}
					}
				}

				return false;
			},
			get_info() {
				costTopReport({
					...this.$refs.list.seo_data
				}).then(res => {
					this.info = res.data;
				})
			},
			see(record) {
				this.visible = true;
				this.$nextTick(res => {
					this.$refs.pop_table_list.set_data('fixed_seo_data', {

						[record.type == 2 ? 'department_id' : 'uid']: record.id,
						...this.$refs.list.seo_data
					})
					this.$refs.pop_table_list.get_list();
				})
			},
			async handleExpandedRowsChange(e) {
				e.forEach(key => {
					let list = JSON.parse(JSON.stringify(this.$refs.list.list));

					let item = this.get_list_key(list, key);

					if (!item.hasOwnProperty('is_req')) {
						getCostSub({
							data: {
								department_id: key,
								...this.$refs.list.seo_data
							}
						}).then(res => {

							item.children = [
								...res.data.list.map((vo) => {
									if (vo.type == 2) {
										vo.children = []
									};
									return vo;
								})

							];
							item.is_req = true;
							this.$refs.list.set_data('list', list);
						})
					}
				});
			},

			getCostInfo(record) {
				this.$router.push({
					path: "/good_list/freezer",
					query: {
						[record.type == 2 ? 'department' : 'uid']: record.id,
						status:1,
						keyword: record.goods_model ? record.goods_model : ''
					}
				});
			},

			list_after(res) {
				let list = this.$refs.list.list;
				list = list.map((item, index) => {
					item.children = [];
					return item;
				});

			},

		}
	};
</script>

<style lang="less">
	@import url("../../assets/less/app.less");

	.visit_img {
		width: 60px;
		height: 60px;
	}
</style>
